import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Container } from '@material-ui/core';
import endpoint from '../../config/endpoint.config';
import { useAuth, useUser } from '../../hooks';
import mudbayIcon from '../../assets/img/mudbayIconWhite.png';
import { useStyles } from './styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


export const Header = () => {
	const { isAuthenticated } = useAuth();
	const { user: { userInfo }, isLoading: isLoadingUser } = useUser();
	const classes = useStyles();
	const [anchorElUser, setAnchorElUser] = React.useState(null);


	const onLogout = () => {
		window.location.href = endpoint.COGNITO_LOGOUT;
		localStorage.clear();
	}

	const onLogin = () => {
		window.location.href = endpoint.COGNITO_LOGIN;
	}

	const settings = [
		(setting) => {
			return isAuthenticated ?
				<MenuItem key={setting} onClick={onLogout} href={endpoint.COGNITO_LOGOUT}>
					<Typography sx={{ textAlign: 'center' }}>
						Logout
					</Typography>
				</MenuItem>
				:
				<MenuItem key={setting} onClick={onLogin} href={endpoint.COGNITO_LOGOUT}>
					<Typography sx={{ textAlign: 'center' }}>
						Cognito Login
					</Typography>
				</MenuItem>
		}
	]

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar className={classes.header}>
			<Container maxWidth='lg'>
				<nav className="navbar sticky-top navbar-expand-sm">
					<div className="container-fluid">
						<a className="navbar-brand" href="/">
							<img src={mudbayIcon}
								alt="Mud Bay Logo"
								width="140"
							/>
						</a>
						<div className="navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav me-auto ms-3 mt-1 mb-lg-0">
								<li className="nav-item dropdown">
									<a
										className="nav-link dropdown-toggle"
										href="#"
										id="navbarDropdown"
										role="button"
										data-bs-toggle="dropdown"
										aria-expanded="false"
										style={{ color: 'white', fontWeight: '600' }}
									>
										Tools
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
										<li><a className="dropdown-item" href="/">Order List</a></li>
										<li><a className="dropdown-item" href="/link">Customer Linking</a></li>
									</ul>
								</li>
							</ul>
							<Box>
								<Tooltip title="Open settings">
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<AccountBoxIcon fontSize="large" color="info" />
									</IconButton>
								</Tooltip>
								<Menu
									sx={{ mt: '45px' }}
									id="menu-appbar"
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									{settings.map((setting) => (
										setting(setting)
									))}
									<Divider />
									{(isAuthenticated && !isLoadingUser && userInfo) ? <MenuItem className={'Mui-disabled'}>{`${userInfo.givenName} ${userInfo.familyName}`}</MenuItem>
										: <MenuItem className={'Mui-disabled'}>Loading...</MenuItem>
									}
								</Menu>
							</Box>
						</div>
					</div>
				</nav>
			</Container>
		</AppBar>
	)
};
